import Form from 'react-bootstrap/Form';

import './App.css';

export default function App() {
  return (
    <div className="App">
      <Form>
        <Form.Group>
          <Form.Label>Please Provide the login key: </Form.Label>
          <Form.Control type="password" placeholder="Password" /> 
        </Form.Group>

      </Form>
    </div>
  );
}
