
import App from './App';
import CostCenterConversion from './apps/CostCenterConversion'

export let routes = [
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/cost_center_conversion",
    element: <CostCenterConversion />
  }
];