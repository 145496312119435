import { ChangeEvent, useState } from "react";
import { Button } from "react-bootstrap";
import { Form } from 'react-bootstrap';

async function handleClick(state: State) {
    if (state.file == null) {
        return
    }
    const data = new FormData();
    data.append('file', state.file);
    const result = await fetch('/api/cost_center_conversion/convert', {
        method: 'POST',
        body: data,
    });
    if (result.status !== 200) {
        const exception = JSON.parse(await result.text());
        alert(`Message failed with status ${result.status}: ${exception.description}`)
        return;
    }
    const blob = await result.blob();
    var downloadUrl = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = downloadUrl;
    tempLink.setAttribute('download', 'converted.xlsx');
    tempLink.click();
}

function fileSelected(evt: ChangeEvent, setState: (state: State) => void) {
    const elem = evt?.currentTarget as HTMLInputElement;
    if (elem.files == null) {
        return;
    }
    for (const file of elem.files) {
        setState({
            file: file
        })
        break;
    }
}

type State = {
    file: null | File
};

export default function CostCenterConversion() {
    const [state, setState] = useState<State>({ file: null });

    return (
        <div className="app">
            <Form.Group>
                <Form.Label>Choose a file</Form.Label>
                <input className="form-control" type="file" onChange={(evt) => fileSelected(evt, setState)} />
                <Button type="submit" onClick={() => handleClick(state)}>Submit</Button>
            </Form.Group>
        </div>
    );
}
